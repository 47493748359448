@import '../../../../Main.module.scss';

.companies {
    &_status_field {
        bottom: 9px;
        margin: 9px 5%;
        line-height: 22px;
        height: 22px;
        width: 90%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        border-radius: 3px;
    }

    &_table_container {
        width: 100%;
        max-width: 100%;
    }

    &_actions_row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        justify-content: space-between;
        flex-direction: row;

        &_input {
            width: 320px;
            height: 40px;
            padding-left: 35px;
            border: 1px solid $stonex-light-gray-blue;
            border-radius: 3px;
            margin: 0px;
        }
    }

    &_actions_search {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &_buttons_row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 20px;
    }

    &_heading {
        font-weight: 700;
        font-size: 21px;
        line-height: 40px;
        margin: 20px 0;
        color: $primary-text-color;
    }

    &_items_per_page {
        padding: 0 0 0 12px;
    }

    &_dropdown_container {
        min-width: 90px;
        margin: 0px;
    }

    &_export_button,
    &_add_company_button {
        padding: 11px 16px;
        text-transform: uppercase;
        border-radius: 3px;
        margin: 0 16px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;

        &:hover {
            cursor: pointer;
            &:disabled {
                cursor: default;
            }
        }
        &:disabled {
            border: 1px solid $stonex-light-gray-blue;
            background: $deactivated-block-color;
            color: $stonex-disabled-light-gray;
        }
    }

    &_color_white {
        color: $plainWhite;
        &:hover {
            color: $plainWhite;
        }
    }

    &_export_button {
        background: $plainWhite;
        border: 1px solid $stonex-light-gray-blue;
        color: $primary-text-color;
    }

    &_export_button_image {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        position: relative;
        bottom: 2px;
    }

    &_search_area_icon {
        position: absolute;
        z-index: 1;
        font-size: 12px;
        margin-left: 10px;
        margin-top: 7px;
        color: $stonex-medium-gray;
    }

    &_actions_container {
        color: $stonex-light-gray-blue;
    }

    &_actions_link_button {
        color: $stonex-blue-theme !important;
    }
}

.add_edit_company {
    &_page_container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        gap: 30px;
    }

    &_license_container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 24px;
    }

    &_submit_action_row {
        width: 451px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    &_input {
        width: 451px;
    }

    &_input_sm {
        width: 130px;
        display: inline-block;
    }

}

.company_users {
    &_header_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        h2 {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: bold;
        }
    }
}

.company_details {
    &_container {
        min-width: fit-content;
        display: flex;
        flex-wrap: wrap;
    }

    &_item{
        margin-right: 15px;
        margin-top: 10px;
    }

    &_licenses{
        display: flex;
        margin-right: 10px;
    }

    &_label {
        margin-top: 0;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px
    }
    &_value {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
}
