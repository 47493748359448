@import '../../../../Main.module.scss';

.value_matrix {
    &_wrapper {
        display: flex;
    }

    &_saved_version_loaded {
        margin-bottom: 18px;
        margin-right: 4px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;

        & img {
            height: 15px;
            margin-bottom: 2px;
        }
    }

    &_title_row {
        display: flex;
        justify-content: space-between;
    }

    &_section_header {
        font-size: 18px;
        line-height: 23px;
        font-weight: 700;
        margin-bottom: 24px;
    }

    &_note {
        margin-bottom: 24px;
    }

    &_inputs {
        min-width: 435px;
        max-width: 40%;
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px 20px 0px 20px;
        margin: 32px 0;
        box-shadow: 1px 1px 4px $stonex-boxshadow-black;
        border-radius: 3px;

        & input {
            text-align: right;
        }
    }

    &_decimal_input {
        width: 100%;
    }

    &_inputs_datepicker input {
        text-align: left;
        font-size: 16px !important;
    }

    &_inputs_datepicker {
        width: 100%;
        max-width: 220px;
    }

    &_inputs_row {
        display: flex;
        gap: 15px;
        width: 100%;
        margin-bottom: 32px;
    }

    &_decimals_row {
        margin-bottom: 32px;
    }

    &_half_first_input {
        width: 50%;
        min-width: calc(50% - 20px);
        margin-right: 20px;
    }

    &_apply_producer_price_index{
        display: flex;
        align-items: center;
    }

    &_above_median {
        flex-direction: column;
    }

    &_apply_producer_price_index {
        margin: auto;
    }

    &_above_median {
        margin-right: auto;
    }

    &_results {
        display: flex;
        max-width: 60%;
        width: min-content;
        margin: 32px 0 0 0;
    }

    &_results_reference_container {
        background-color: $plainWhite;
        padding-left: 32px;
    }

    &_download {
        margin-left: -35px;
        button {
            padding-top: 0;
        }
    }

    &_download_image {
        height: 24px;
        width: 24px;
        margin-left: 4px;
        @include hover-cursor-pointer;
        vertical-align: top;
    }

    &_results_header {
        h3 {
            margin-top: 0;
        }
    }

    &_results_table {
        display: flex;
        width: 100%;
    }

    &_results_table_header_term_classifications_row {
        background-color: $stonex-light-gray-white;
        font-weight: 700;
    }

    &_results_table_header_row {
        font-weight: 700;
    }

    &_results_table_header_row,
    &_results_table_decile_row,
    &_results_table_header_term_classifications_row {
        display: flex;
        border: 1px solid $stonex-gray-blue-border;
        width: 100%;
        border-top: none;
        min-height: 48px;
        line-height: 48px;
    }

    &_results_table_cell,
    &_results_header_cell {
        text-align: center;
        min-width: 144px;
        border-right: 1px solid $stonex-gray-blue-border;
    }

    &_full_width {
        width: 100%;
        min-width: 288px;
        height: 100%;
    }

    &_results_table_cell:last-of-type,
    &_results_header_cell:last-of-type {
        border-right: none;
        min-width: 144px;
    }

    &_primary_header {
        margin-left: 144px;
        border-top: 1px solid $stonex-gray-blue-border;
        margin-top: 10px;
    }

    &_results_table_header_row,
    &_results_table_decile_row:nth-child(even) {
        background-color: $deactivated-block-color;
    }

    &_results_table_decile_row:nth-child(8),
    &_sticky_column_group_cell:nth-child(8) {
        border-top: 2px solid $decile-median-line;
    }

    &_sticky_column_group_cell {
        position: relative;
    }

    &_sticky_column_group_median {
        position: absolute;
        left: 20px;
        top: 50%;
        width: 12px;
        height: 3px;
        border-radius: 3px;
        background-color: $decile-median-line;
    }

    &_results_table_decile_row:nth-child(odd) {
        background-color: $plainWhite;
    }

    &_dropdown {
        width: 100%;

        & div label {
            font-family: $application-font-family;
            color: $primary-text-color;
        }
    }

    // Don't remove important keywords. This is correction for Mono style bleed.
    &_checkbox_dropdown_label,
    &_apply_producer_price_index,
    &_dropdown div p,
    &_chart_dropdown p {
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    &_checkbox_dropdown_label,
    &_dropdown div p {
        margin-bottom: 12px !important;
    }

    &_sticky_column_group_cell {
        text-align: center;
        margin: auto;
        width: 144px;
        font-weight: 700;
        font-size: 14px;
        line-height: 48px;
        border-bottom: 1px solid $stonex-gray-blue-border;
    }

    &_go_button {
        margin: 0 0 28px;

        & div:nth-child(1) {
            top: 12px;
        }

        & button {
            width: 100%;
        }
    }

    &_chart_section {
        height: 650px;
        width: 100%;
    }

    &_chart_close {
        @include hover-cursor-pointer;
    }

    &_chart_button_set {
        display: flex;
        float: right;
        align-items: baseline;

        & p {
            font-weight: 600;
            font-size: 12px;
            color: $stonex-medium-gray !important;
        }

        & button {
            margin-left: 15px;
        }
    }

    &_chart_controls {
        display: flex;
        gap: 20px;
    }

    &_chart_controls_title {
        display: flex;
        justify-content: space-between;
        margin: 32px 0 20px 0;
    }

    &_chart_dropdown {
        width: 100%;
    }

    &_chart_area {
        width: calc(100% - 130px);
        height: calc(100% - 130px);
    }

    &_chart_secondary_legend {
        width: 100px;
        margin: 15px;
    }

    &_chart_right_side {
        display: flex;
        font-size: 14px !important;
        width: 100% !important;
        margin: 10px 0;
    }

    &_chart_right_side_label {
        width: 10px;
        height: 10px;
        margin: 7px;
    }

    &_generated_text {
        text-align: right;
        line-height: 15px;
        font-size: 12px;
        font-weight: 600;
        color: $stonex-medium-gray;
        margin-top: 12px;
    }

    &_download {
        &_image {
            height: 24px;
            width: 24px;
            margin-left: 4px;
            @include hover-cursor-pointer;
            vertical-align: top;
            margin-bottom: 10px;

            &_button {
                padding: 0 !important;
            }
        }
    }
}
